import { API_AI_REROLL_TEXT, SOCKET_URL } from "Constants";
import { api } from "./api";
import { io } from 'socket.io-client';
import { getBySocket } from "./socket";

export const getNewTextWithAI = async (text) => {
  if (!text) {
    return '';
  }
  // const data = {
  //   generate: 'similar',
  //   text: text
  // };
  // const res = await api(API_AI_REROLL_TEXT, 'POST', data);
  // return res.newText;
  const socket = io(SOCKET_URL);
  const response = await getBySocket({
    emitEventName: 'ai-page-regenerate',
    resultEventName: 'response-page-regenerate',
    payload: { text },
    socket
  });
  return response.text;
}

export const getNewImageWithAI = async (text) => {
  if (!text && text.trim().length === 0) {
    return;
  }
  return new Promise((resolve, reject) => {
    const socket = io(SOCKET_URL);
    socket.emit('generate-page-image', { text, uid: generateFingerPrint() });

    socket.on('page-image-generated', data => {
      resolve(data.imageUrl)
    })
  });
}

export function generateFingerPrint() {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  const date = new Date();
  const timestamp = date.valueOf();
  const randomString = `${timestamp}-${result}`;
  localStorage.setItem("MechFingerprint", randomString);
  return randomString;
}