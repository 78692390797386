import React, { useEffect, useState, useRef, useReducer } from 'react';
import { eventEmiter, rxRerollImageData, rxSelectedBlocks } from '../../rx/rxState';
import TextEditorToolbar from '../TextEditor/TextEditorToolbar';
import styled from 'styled-components';
import ColorSelector from '../ColorSelector';
import AttributeComponent from '../../Attributes/AttributeComponent';
import { rxPriceError } from '../../rx/rxState';
import { useObservable } from 'utils/UseObservable';

import ToolbarSection from './ToolbarSection';
import RerollComponent from 'Components/AIRerollImage/RerollComponent';

const ToolbarStyle = styled.div`
    color: black;
`

const ErrorTextStyle = styled.div`
    width: 100%;
    text-align: center;
    color: red;
    font-family: Quicksand;
    font-size: 18px;
    font-weight: 600;
    line-height: 2;
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 2100;
`;

const ToolbarBlocks = (props) => {
    let selectedBlockSub = null;
    let updateSub = null;

    const [block, setBlock] = useState( null );
    const [counter, setCounter] = useState( 0 ); //Forced Update counter
    const forcedUpdate = () => setCounter((count) => count + 1);
    const priceError = useObservable(rxPriceError);

    const [top, setTop] = useState(0);
    const rerollBlockImageData = useObservable(rxRerollImageData);

    const originalRef = useRef(null);

    useEffect(() => {
        if (!selectedBlockSub) {
            selectedBlockSub = rxSelectedBlocks.subscribe({
                next: (v) => {
                    if (v.length > 0) {
                        setBlock( v[0] );
                    } else {
                        setBlock( null );
                    }
                    if(block === null || (rerollBlockImageData && rerollBlockImageData.block.id !== block.id)){
                        rxRerollImageData.next( null )
                    }

                    update();
                }
            })
        }

        updateSub = eventEmiter.subscribe({
            next: (e) => {
              if (e.type == 'view-on-scroll') {
                update();
              }
            },
          });

        return () => {
            selectedBlockSub.unsubscribe();
            updateSub.unsubscribe();
        }
    })


    const saveOriginalImage = () => {
        if(rerollBlockImageData){
            if (rerollBlockImageData.block.image) {
                originalRef.current = rerollBlockImageData.block.image;
            }
            if (rerollBlockImageData.block.imageUrl) {
                originalRef.current = rerollBlockImageData.block.imageUrl.value
            }
        }
      }

      const restoreOriginalImage = () => {
        if (rerollBlockImageData.block.image ||  rerollBlockImageData.block.imageUrl) {
            rerollBlockImageData.block.image = originalRef.current;
            rerollBlockImageData.block.imageUrl.value = originalRef.current
            originalRef.current = null;
            rerollBlockImageData.block.view.update()
        }
        rxRerollImageData.next(null)
      }

      const handleChangeImage = (url) => {
        rerollBlockImageData.block.imageUrl.value = url
        rerollBlockImageData.block.view.update()
      }

      const handleSubmit = (url) =>{
        rxRerollImageData.next(null)
      }
      
    const onColorChange = (color)=>{
        block.color = color;
        eventEmiter.next({
            type: 'update-view'
        });
        forcedUpdate()
    };

    const onAttributeChanged = (attrName, newValue) => {
        block[attrName].value = newValue;

        // block.update();
        // console.log();
        //FORCED UPDATE
        forcedUpdate()
    }


    const update = () =>{
        if(rerollBlockImageData){
            const y = rerollBlockImageData.block.worldRenderBRect.top;
            setTop(y);
        }
    }


    const getToolbar = (block) => {
        if(block){
            let attributes = block.attributes;
            return(
                
                <div style={{display:'flex'}}>
                    <TextEditorToolbar
                        block={block}
                    />
                    {attributes.map((attr)=>{
                        if(attr.visible !== undefined && attr.visible === false){
                            return null;
                        }
                        return <AttributeComponent
                                attribute={attr}
                                block={block}
                                key={attr.id}
                                onChange={onAttributeChanged}
                            />
                    })}

            </div>
            )

        }

        return null;
    }
    const filterChildren = ( filteredChildren, block ) => {
        for( let child of block.children){
            if((child.type !== 'Column' && child.type !== 'Placeholder' && child.isSelectable === true && child.canHide === undefined)
            || child.canHide === true ){
                filteredChildren.push( child );
            }
            if(child.type !== 'Button'){
                filterChildren(filteredChildren, child);
            }
        }
    }

    const getToolbarSection = (block)=>{
        if(!block || block.type !== 'Section'){
            return null;
        }

        let visibilityBlocks = [];
        filterChildren( visibilityBlocks, block);

        return (
        <ToolbarSection
            block={block}
            visibilityBlocks={visibilityBlocks}
                />
        )
    }

    return (        
        <>
            { getToolbarSection( block) }
            <ToolbarStyle>
                {rerollBlockImageData && <RerollComponent
                x={rerollBlockImageData.block.worldRenderBRect.left + 80 +(rerollBlockImageData.block.worldRenderBRect.width - 600 )/2.0} // 600 is a width of AI re-roll toolbar, 80px is a width of side pannel 
                y={top + 10}
                immediatelyOpen={true}
                onChange={handleChangeImage}
                onStart={saveOriginalImage}
                onCancel={restoreOriginalImage}
                onSubmit={handleSubmit}
                /> }
                {getToolbar( block )}
                {priceError && <ErrorTextStyle>{priceError}</ErrorTextStyle>}
            </ToolbarStyle>

        </>
    )
};
export default ToolbarBlocks;
