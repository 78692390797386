import React, { Fragment } from 'react';
import { Block, BlockStyle } from './Block';
import { EditorState, convertFromRaw, convertToRaw, Modifier } from 'draft-js';
import TextEditor from '../../Components/TextEditor/TextEditor';
import styled from 'styled-components';
import { AIGenerator, SectionController, sectionFromBlockData } from 'page-ai-generator/generation';
import { eventEmiter, rxIsLoading } from 'rx/rxState';
import { getNewTextWithAI } from 'utils/rerollTextWithAI';

const BlockTextStyle = styled(BlockStyle)`
    pointer-events: auto;
    user-select: ${props => props.selected ? 'auto' : 'none'};
`
export class BlockTextProxy extends React.Component {
    constructor(props) {
        super(props);
        this.textEditor = null;
        this.state = {
            edited: false,
            content: this.props.content,
            styleMap: this.props.styleMap,
        };

        this.props.contentUpdate((value) => {
            this.setState({ content: value });
        })

        this.props.editingHandle((value) => {
            this.setState({ editing: value });
        });

        this.props.contentHandle((editorState, styleMap) => {
            this.setState({
                content: editorState,
                styleMap: styleMap,
            });
        });
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.props.reference.current.style.cssText = this.props.styleText();
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.styleText !== this.props.styleText) {
            this.props.reference.current.style.cssText = this.props.styleText();
        // }
    }
    onChange(editorState) {
        this.setState({ content: editorState });
        this.props.onChange(editorState);
    }
    render() {

        return (
            <BlockTextStyle
                id={this.props.id}
                key={this.props.id}
                selected={this.props.selected || this.props.view.props.liveMode}
                ref={this.props.reference}
                style={this.props.style}
                width={this.props.width}
                className={this.props.className}
            >
                <TextEditor
                    isMobile={this.props.view.isMobileLayout()}
                    liveMode={this.props.view.props.liveMode}
                    ref={(editor) => {
                        this.textEditor = editor;
                    }}
                    content={this.state.content}
                    selected={this.props.selected}
                    onChange={(e) => this.onChange(e)}
                    readOnly={this.props.view.props.liveMode || this.props.view.state.isMouseDragging}
                />
            </BlockTextStyle>
        );
    }
}

export class BlockText extends Block {
    constructor(props) {
        super(props);

        this.type = 'Text';

        let defaultContent = {
            blocks: [
                {
                    key: '886ni',
                    text: 'Your Text',
                    type: 'align-center-item',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                },
            ],
            entityMap: {},
        };
        let content = convertFromRaw(defaultContent);
        let editorState = EditorState.createWithContent(content);

        let attr = {
            name: 'Text',
            type: 'AttributeText',
            editorState: editorState,
            styleMap: {}
        };
        this.text = attr;

        attr = {
            id: 'textClass',
            displayName: 'Style',
            value: 's-paragraph',
            type: 'AttributeDropdown',
            options: [
                {
                    label: 'H1 Title',
                    value: 's-h1title'
                },
                {
                    label: 'H2 Title',
                    value: 's-h2title'
                },
                {
                    label: 'H3 Title',
                    value: 's-h3title'
                },
                {
                    label: 'Subtitle',
                    value: 's-subtitle'
                },
                {
                    label: 'Uppernote',
                    value: 's-uppernote'
                },
                {
                    label: 'Paragraph Large',
                    value: 's-paragraph-l'
                },
                {
                    label: 'Paragraph Normal',
                    value: 's-paragraph-n'
                },
                {
                    label: 'Paragraph Small',
                    value: 's-paragraph-s'
                },
                {
                    label: 'Paragraph Mini',
                    value: 's-paragraph-m'
                }

            ],
        }
        this.addAttribute(attr);

        this.contentUpdateRef = null
        this.onChange = this.onChange.bind(this);
    }


    unpack(data) {
        super.unpack(data);
        this.text = data['text'];
        this.text.editorState = EditorState.createWithContent(convertFromRaw(this.text.value));
    }

    pack() {
        let data = super.pack();
        data['text'] = {};
        data['text'].value = convertToRaw(this.text.editorState.getCurrentContent());

        //temprorary removing ability to have children for text blockTEXT BLOCK 
        data['children'] = [];
        return data;
    }

    updateContet() {
        //Sending new state to ToolBar
        eventEmiter.next({
            type: 'text-attribute-changed-editor',
            payload: {
                editorState: this.text.editorState
            }
        })

        //Triggering Update of DraftTextEditor
        this.contentUpdateRef(this.text.editorState)
    }

    async rerollAiTextForNewBlock() {
        rxIsLoading.next('Update');
        const data = this.pack();
        let text = '';
        data.text.value.blocks.forEach(block => {
            if (block.text && typeof block.text === 'string') {
                text += block.text + "\n";
            }
        });

        const newText = await getNewTextWithAI(text);

        const section = new SectionController(sectionFromBlockData(data))
        this.text.value = section.updateReRollText(newText, this);
        const editorState = EditorState.createWithContent(convertFromRaw(this.text.value));

        this.text.editorState = editorState

        setTimeout(() => {
            this.updateContet()
            rxIsLoading.next('');
        }, 400);
    }

    async rerollAiText() {
        try {
            rxIsLoading.next('Update');
            const data = this.pack();
            const text = data.text.value.blocks[0].text;
            const newText = await getNewTextWithAI(text);

            const section = new SectionController(sectionFromBlockData(data))
            this.text.value = section.updateReRollText(newText, this);
            const editorState = EditorState.createWithContent(convertFromRaw(this.text.value));

            this.text.editorState = editorState

            setTimeout(() => {
                this.updateContet()
                rxIsLoading.next('');
            }, 400);
            // /** @type {import('types/ai').AIResult} */
            // const query = {
            //     ...(this.view.aiSuggestion || {}),
            //     generate: this.aiField
            // }

            // /** @type {AIGenerator} */
            // const ai = new AIGenerator();
            // await ai.getSuggestion(query);            ///

            // const section = new SectionController(sectionFromBlockData(this.pack()))
            // section.updateText(ai.aiSuggestion[this.aiField], this.aiField);
            // this.view.aiSuggestion = {
            //     ...this.view.aiSuggestion,
            //     ...ai.aiSuggestion
            // };
            // this.unpack(section.data);
            // // this.onChange(this.text.editorState)
            // setTimeout(() => {
            //     this.updateContet()
            //     rxIsLoading.next('');

            // }, 400);
        } catch (e) {
            console.error('[Error] ', e);
            rxIsLoading.next('');
        }
    }
    contentUpdate(ref) {
        this.contentUpdateRef = ref;
    }
    onEditing() { }
    onChange(editorState) {
        setTimeout(() => { //Delayed update change so Text field can have updated Element sizes.
            this.view.needsLayout();
        }, 10)

        for (let sub of this.onChangeSubscribers) {
            sub(editorState);
        }
        this.text.editorState = editorState;
    }
    renderView() {
        return (
            <Fragment key={this.id}>
                {this.isVisible ?
                    <>
                        <BlockTextProxy
                            id={this.id}
                            key={`${this.id}`}
                            view={this.view}
                            width={this.worldRenderBRect ? this.worldRenderBRect.width : 0}
                            content={this.text.editorState}
                            styleMap={this.text.styleMap}
                            onChange={this.onChange}
                            editingHandle={this.editingHandle.bind(this)} //TODO: seems like we can remove it
                            contentHandle={this.textEditorStateHandle.bind(this)} //TODO: seems like we can remove it
                            contentUpdate={this.contentUpdate.bind(this)}
                            reference={this.ref}
                            selected={this.isSelected}
                            styleText={this.style}
                            className={this.className + this.textClass.value}
                        />
                    </>
                    :
                    null}
            </Fragment>
        );
    }
}
