import React from 'react';
import styled from 'styled-components';
import StyleButton from './StyleButton';
import { RichUtils } from 'draft-js';
import { ReactComponent as OrderedList } from '../../Assets/list-ol-solid.svg';
import { ReactComponent as UnorderedList } from '../../Assets/list-ul-solid.svg';

const ControlStyle = styled.div`
  display: flex;
  margin-right: 10px;
`;

const BLOCK_TYPES = [
  {
    icon: <UnorderedList />,
    label: 'UL',
    style: 'unordered-list-item',
  },
  { 
    icon: <OrderedList />, 
    label: 'OL',
    style: 'ordered-list-item' 
  },
];

export default class BlockStyleControls extends React.Component {
  onToggle(blockType) {
    this.props.onChange(
      RichUtils.toggleBlockType(this.props.editorState, blockType)
    );
  }

  render() {
    const { editorState } = this.props;
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    return (
        <ControlStyle>
          {BLOCK_TYPES.map((type) => (
            <StyleButton
              key={type.label}
              active={type.style === blockType}
              icon={type.icon}
              onToggle={this.onToggle.bind(this)}
              style={type.style}
            />
          ))}
        </ControlStyle>
    );
  }
}
