import React from 'react';
import styled from 'styled-components';

import { ReactComponent as DesktopIcon } from '../Assets/navigationBarIcons/desktop.svg';
import { ReactComponent as MobileIcon } from '../Assets/navigationBarIcons/mobile.svg';

const SwitchButtonStyled = styled.span`
  display: inline-block;

  padding: 0px 18px;
  overflow: hidden;

  color: #000;

  > label {
    cursor: pointer;
    display: inline-block;
    width: 30px;
    height: 30px;

    > input {
      display: none;
    }

    > input + div {
      // border: 1px #c6cacf solid;
      background: #ffffff;
    }

    &:first-of-type > input + div {
      border-radius: 5px 0 0 5px;
      border-right: none;
    }

    &:last-of-type > input + div {
      border-radius: 0 5px 5px 0;
      border-left: none;
    }
    
    > input:checked + div {
      background: #000000;
      border-color: #000000;

      > svg {
        > path {
          fill: #fff;
        }
      }
    }

    > div {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const SwitchButton = (props) => {
  return (
    <SwitchButtonStyled>
      <label>
        <input
          type="radio"
          value="Responsive"
          name="desktop-size"
          checked={props.value === 'Responsive'}
          onChange={(v) => props.onChange(v.currentTarget.value)}
        />
        <div>
          <DesktopIcon />
        </div>
      </label>
      <label>
        <input
          type="radio"
          value="Mobile"
          name="desktop-size"
          checked={props.value === 'Mobile'}
          onChange={(v) => props.onChange(v.currentTarget.value)}
        />
        <div>
          <MobileIcon />
        </div>
      </label>
    </SwitchButtonStyled>
  );
};

export default SwitchButton;
