import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './sectionsStyle.scss';

import App from './App';
// import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';
import { GoogleOAuthProvider } from '@react-oauth/google';

const GOOGLE_CLIENT_ID = '251796105287-hhiv3r5s2d87c796e9pjs2phiindc0em.apps.googleusercontent.com';
// const initSentry = () => {
//   Sentry.init({
//     dsn:
//       'https://df4db70fe84448a28793bbef4f0e5149@o457020.ingest.sentry.io/5450647',
//   });
// };


//TODO: need to CLEAN THIS MESS eventually 
let urlComponents = window.location.pathname.split('/');
console.log(urlComponents);
let editMode = false;
let userId = '';
let productId = '';
let page = '';
let productMode = false;
if (['edit', 'view', 'login', 'generate', 'signup', 'signup2', 'pricing', 'special-discount', 'no-trial-special', 'book', 'book-editor', 'html', 'test', 'ai-template'].includes(urlComponents[2])) {
  //EDITOR MODE
  editMode = true;
  userId = urlComponents[2];
  productId = urlComponents[3];
  page = urlComponents[4];
}
//LIVE MODE Preview
else if (urlComponents.length === 5 && urlComponents[1] === 'edit') {
  // initSentry();
  productId = urlComponents[3];
  page = urlComponents[4];
}
//LIVE MODE Production
else {
  // initSentry();
  editMode = false;
  productMode = true;
}

ReactDOM.hydrate(
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <App
      editMode={editMode}
      page={page}
      productId={productId}
      userId={userId}
      productMode={productMode}
    />
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
