import { io, Socket } from 'socket.io-client';

interface IGetSocketArgs {
  emitEventName: string;
  resultEventName: string
  socket: Socket
  payload: any
}

export const getBySocket = async ({ emitEventName, resultEventName, socket, payload }: IGetSocketArgs) => {
  return new Promise((resolve) => {
    socket.emit(emitEventName, payload);
    socket.on(resultEventName, (res: any) => {
      console.log('got response ', res)
      resolve(res)
    });
  });
}