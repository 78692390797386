import React from 'react';
import styled from 'styled-components';

const StyleButtonStyle = styled.div`
  background: ${(props) => (props.active ? '#000' : 'white')};
  //border: #cbcbcb 1px solid;
  border-radius: 4px;
  margin-right: 5px;
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => (props.active ? '#fff' : '#000')};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    // width: 14px;
    // height: 14px;
    > path{
      fill: ${(props) => (props.active ? '#fff' : '#000')};
    }
  }
`;

export default class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      if (this.props.onToggle) {
        e.preventDefault();
        this.props.onToggle(this.props.style);
      }
    };
  }

  render() {
    return (
      <StyleButtonStyle active={this.props.active} onMouseDown={this.onToggle}>
        {this.props.icon}
      </StyleButtonStyle>
    );
  }
}
