import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { applyStyle, getStyleChar } from './StyleControlsUtils';

import {FontSizeStyleMap} from './StyleMaps';

import Dropdown from '../../Components/Dropdown';
import { Input } from '../../Attributes/Styles';

const ControlStyle = styled.div`
  position: relative;
  display: flex;
  margin-right: 10px;
  justify-content: space-between;
  height: initial;
  text-align: right;
  align-items: center;
  z-index: 1003;
  .no-edit {
    .name {
      display: inline-block;
      width: 50px;
    }
    .warning {
      color: yellow;
    }
  }
  width: 70px;
`;

const FreeSizeEdit = styled.div`
  width: 90px;
  margin-left: 10px;
  input {
    width: 100%;
    padding-right: 20px;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  &::after {
    content: "px";
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 5px;
    height: 100%;
    color: grey;
  }
`

const SizeOptions = [
  {
    label:'Tiny',
    value: 'Tiny',
    size: '12px'
  },
  {
    label:'Small',
    value: 'Small',
    size: '15px'
  },
  {
    label:'Medium',
    value: 'Medium',
    size: '20px'
  },
  {
    label:'Normal',
    value: 'Normal',
    size: '25px'
  },
  {
    label:'Large',
    value: 'Large',
    size: '30px'
  },
  {
    label:'Huge',
    value: 'Huge',
    size: '40px'
  },
  {
    label:'Epic',
    value: 'Epic',
    size: '60px'
  }
]


const customValue = 'Custom';


const SizeStyleControls = (props) => {
  const [value, setValue] = useState(undefined);
  const selectEl = useRef(null);

  const inputValue = parseFloat((FontSizeStyleMap()[`size-${value}`])?.fontSize || value);

  useEffect(() => {
    const newValue = getValue(props.editorState);
    setValue(newValue);
  }, [props.editorState]);

  const getSizeFromChar = (char) => {
    if (!char) {
      return 0;
    }

    const arr = char.getStyle().toArray();
    let size = 'Normal'; //default font size
    arr.forEach((style) => {
      if (style.search('size-') === 0) {
        size = style.substring(5);
      }
    });
    return size;
  };

  const getValue = (editorState) => {
    let char = getStyleChar( editorState );
    return getSizeFromChar( char );
  };

  const onChange = (item, name) => {
    const style = 'size-'+item.value;
    const oldStyle = 'size-' + getValue(props.editorState);
    let nextEditorState = applyStyle( props.block, props.editorState, style, {...FontSizeStyleMap(), [oldStyle]: {}});
    props.onChange(nextEditorState);
  };


  const onInput = (e) => {
    if (e.target.value) {
      const value = `${Math.max(Math.min(e.target.value, 200), 1)}px`;
      const inPreset = SizeOptions.find(item => item.size == value);
      return onChange({value: inPreset?.value || value});
    }
    setValue(undefined);
  };

  return (
    <ControlStyle>
      {/* <Dropdown
        name="size"
        title={SizeOptions.find(item => item.value == value) ? value : customValue}
        list={SizeOptions}
        onChange={onChange}
      /> */}
      <FreeSizeEdit>
        <Input min={1} max={200} type="number" value={isNaN(inputValue) ? 0 : inputValue} onChange={onInput} />
      </FreeSizeEdit>
    </ControlStyle>
  );
};

export default SizeStyleControls;
