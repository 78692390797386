import React from 'react';
import s from './RerollInput.module.scss';
import ArrowSvg from 'Assets/icons/arrowDown.svg';
import CloseSvg from 'Assets/icons/x.svg';

interface IProps {
  x: number;
  y: number;
  onClose: () => void;
  onSubmit: () => void;
  text: string;
  onTextChange: (newText: string) => void;
  loading: boolean;
  onCancel: () => void;
  percent: number;
}


const RerollInput = (props: IProps) => {
  return (
    <div className={s.rerollInput} style={{top: props.y, left: props.x}}>
      {props.loading ? <div className={s.loadingBar}>
        <div>
          <div className={s.progressBar}>
            <div className={s.progressPercent} style={{width: `${props.percent}%`}}>
            </div>
          </div>
        </div>
        <button className={s.cancelButton} onClick={() => props.onCancel()}>
          Cancel
          &nbsp;
          <img src={CloseSvg} alt="x" />
        </button>
      </div> :
      <div>
        <input
          className={s.aiInput}
          value={props.text}
          onChange={e => {e.stopPropagation(); props.onTextChange(e.target.value)} }
        />
        <button className={s.aiSubmit} onClick={() => props.onSubmit()}>
          Generate
          &nbsp;
          <img src={ArrowSvg} />
        </button>
        <button className={s.aiClose} onClick={() => props.onClose()}>
          <img src={CloseSvg} alt="x" />
        </button>
      </div>}
    </div>
  )
}

export default RerollInput;