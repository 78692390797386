import Spinner from 'Components/Common/Spinner/Spinner';
import React, { useState, useRef, useEffect } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import ContentEditableDiv from '../ContentEditableDiv/ContentEditableDiv';
import { IBookChapter } from '../types';
import s from './BookInfoBlockEditor.module.scss';
import { ReactComponent as AddNewChapter } from '../../../Assets/addNewChapter.svg';
import clsx from 'clsx';
import {
  rxActiveChapterIndex,
  rxBlockIndexForBook,
  rxCurrentEditorBlockIndex,
} from 'rx/rxState';
import ChapterItemEditor from '../ChapterItem/ChapterItemEditor';
import { useObservable } from 'utils/UseObservable';

interface IProps {
  bookTitle: string;
  onBookTitleEdited: (value: string) => void;
  onChapterTitleEdited: (value: string, index: number) => void;
  chapters: IBookChapter[];
  loadingChapter: boolean;
  loadingAddChapter: boolean;
  deleteChapter: (index: number) => void;
  onChapterAdd: (indexBefore: number, action: 'add' | 'insert') => void;
  reorderChapter: (dragIndex: number, dropIndex: number) => void;
  endReorderChapter: () => void;
  onChapterRegenerate: (index: number) => void;
  addingChapterIndex: null | number;
  isGeneratedAllBookEnd: boolean;
  indexGenarateChapterText?: number;
  loadingRegenerateChapterData: { chapterIndex: number; loading: boolean };
}

function BookInfoBlockEditor({
  bookTitle,
  onBookTitleEdited,
  chapters,
  loadingChapter,
  onChapterTitleEdited,
  deleteChapter,
  onChapterAdd,
  reorderChapter,
  endReorderChapter,
  onChapterRegenerate,
  loadingAddChapter,
  isGeneratedAllBookEnd,
  indexGenarateChapterText,
  loadingRegenerateChapterData,
  addingChapterIndex,
}: IProps) {
  const [bookTitleInput, setBookTitleInput] = useState<string>(bookTitle);
  const [activeChapter, setActiveChapter] = useState<number>(0);
  const currentEditorBlockIndex = useObservable(rxCurrentEditorBlockIndex);
  const [isBookTitleActive, setIsBookTitleActive] = useState<boolean>(false);
  const [isBookTitleLongError, setIsBookTitleLongError] = useState<boolean>(false);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [lastSortingTimestamp, setLastSortingTimestamp] = useState<
    number | null
  >(null);

  const handleNameInput = (value: string) => {
    let textValue = value
    if (textValue.length > 150) {
      textValue = textValue.slice(0, 150);
      setIsBookTitleLongError(true)
    } else{
      setIsBookTitleLongError(false)
    }
    const words = textValue.split(' ');
    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        return word[0].toUpperCase() + word.slice(1);
      }
      return word;
    });
    const capitalizedValue = capitalizedWords.join(' ');

    setBookTitleInput(capitalizedValue);
  };

  useEffect(() => {
    setBookTitleInput(bookTitle);
  }, [bookTitle]);

  useEffect(() => {
    if (currentEditorBlockIndex === 0) {
      setIsBookTitleActive(true);
    } else {
      setIsBookTitleActive(false);
    }
    if (
      currentEditorBlockIndex &&
      currentEditorBlockIndex - 1 !== activeChapter
    ) {
      setActiveChapter(currentEditorBlockIndex - 1);
    }
  }, [currentEditorBlockIndex]);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      const currentTime = Date.now();
      if (lastSortingTimestamp && currentTime - lastSortingTimestamp > 1000) {
        endReorderChapter();
      }
    }, 1000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [lastSortingTimestamp]);

  const onTitleInputClick = () => {
    // setIsBookTitleActive(true);
    rxActiveChapterIndex.next(0);
  };

  const handleChapterSorting = (res: DropResult) => {
    const { destination, source, draggableId } = res;

    if (!destination) {
      return;
    }
    if (isGeneratedAllBookEnd) {
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }
      setLastSortingTimestamp(Date.now());
      reorderChapter(source.index, destination.index);
    }
  };
  return (
    <div>
      <div
        className={clsx(s.bookInfoEditNameInputEditVersion, {
          [s.bookInfoEditNameInputEditVersionActive]: isBookTitleActive,
        })}
      >
        <div className={s.bookInfoEditNameInputInner}>
          <ContentEditableDiv
            name={bookTitleInput}
            fontSize={14}
            lineHeight={20}
            fontWeight={500}
            width={300}
            height={50}
            onChange={!isGeneratedAllBookEnd ? () => {} : handleNameInput}
            onEdit={!isGeneratedAllBookEnd ? () => {} : onBookTitleEdited}
            onClick={() => onTitleInputClick()}
          />
          {isBookTitleLongError && <div className={s.errorText}>Maximum characters 150</div>}
        </div>
      </div>
      <DragDropContext onDragEnd={handleChapterSorting}>
        <Droppable droppableId="chapters">
          {(provided) => (
            <div
              className={s.chaptersBlockEditVersion}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {chapters.map((chapter: IBookChapter, index) => (
                <Draggable
                  key={chapter.title + index.toString()}
                  draggableId={`chapter-${index}`}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ChapterItemEditor
                        index={index}
                        chapter={chapter}
                        onChapterTitleEditedProps={onChapterTitleEdited}
                        activeChapter={activeChapter}
                        setActiveChapter={setActiveChapter}
                        deleteChapter={deleteChapter}
                        onChapterAdd={onChapterAdd}
                        onChapterRegenerate={onChapterRegenerate}
                        loadingAddChapter={loadingAddChapter}
                        isGeneratedAllBookEnd={isGeneratedAllBookEnd}
                        indexGenarateChapterText={indexGenarateChapterText}
                        isBookTitleActive={isBookTitleActive}
                        addingChapterIndex={addingChapterIndex}
                        setIsBookTitleActive={setIsBookTitleActive}
                        loadingRegenerateChapterData={
                          loadingRegenerateChapterData
                        }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {!loadingChapter && !loadingRegenerateChapterData.loading && (
                <div
                  className={s.addNewChapter}
                  onClick={
                    loadingAddChapter ||
                    !isGeneratedAllBookEnd ||
                    loadingRegenerateChapterData.loading
                      ? () => {}
                      : () => onChapterAdd(chapters.length, 'add')
                  }
                >
                  {loadingAddChapter || !isGeneratedAllBookEnd ? (
                    !isGeneratedAllBookEnd ? null : (
                      <Spinner size={25} />
                    )
                  ) : (
                    <AddNewChapter />
                  )}
                </div>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default BookInfoBlockEditor;
