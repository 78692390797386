// import debounce from 'lodash/debounce';
import React, { useState, useEffect, useMemo } from 'react';
import { GithubPicker, SketchPicker } from 'react-color';
import styled from 'styled-components';
import {Colors} from './TextEditor/StyleMaps';

const ViewBlockStyle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background: ${({color}) => color.hex || color};
  border: 5px white solid;
`;

const ColorSelector = (props) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [selectedColor, setColor] = useState(props.color);

    const onColorClick = () => {
        setDisplayColorPicker(!displayColorPicker);
      };

      const onClose = () => {
        setDisplayColorPicker(false);
      };

    const popover = {
        position: 'absolute',
        top: '40px',
        // left: '20px',
        zIndex: 2010
      };

      const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      };
    useEffect(() => {
      if (props.color != selectedColor || props.color != selectedColor.hex) {
        setColor(props.color);
      }
    }, [props.color])

    // const applyColor = useMemo(() => {
    //   return debounce((color) => {
    //     props.onChange(color);
    //   }, 50);
    // }, [props.onChange])

    const changeColorByPicker =  (value) => {
      setColor(value);
      props.onChange(value.hex);
      // applyColor(value.hex);
    };



    return (
        <>
        <ViewBlockStyle onClick={() => onColorClick()} color={selectedColor} />
        {displayColorPicker && (
          <div style={popover}>
            <div style={cover} onClick={() => onClose()} />
            <SketchPicker
              width="188px"
              disableAlpha
              presetColors={Colors}
              onChange={(v) => changeColorByPicker(v)}
              color={selectedColor}
            />
          </div>
        )}
        </>
    )
}

export default ColorSelector;